:root {
  --true-blue-color: #0466C8;
  --usafa-blue-color: #0353A4;
  --dark-cornflower-blue-color: #023E7D;
  --oxford-blue-light-color: #002855; 
  --oxford-blue-color: #001845;
  --oxford-blue-dark-color: #001233;
  --independence-color: #33415C;
  --black-cora-colorl: #5C677D;
  --roman-silver-color: #7D8597;
  --manatee-color: #979DAC;
}


:root{
  --body-font: 'Open Sans', sans-serif;
  --big-font-size: 5rem;
  --h1-font-size: 3rem;
  --h2-font-size: 1.5rem;
  --normal-font-size: 1rem;
  --small-font-size: .813rem;
}
@media screen and (max-width: 830px){
  :root{
    --big-font-size: 3rem;
    --h1-font-size: 2rem;
    --h2-font-size: 1.25rem;
    --normal-font-size: .938rem;
    --small-font-size: .75rem;
  }
}

body {
  margin: 0;
  padding: 0;
  background-color: #F3F2F3;
  font-family: sans-serif;
}